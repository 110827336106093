import path from 'path';
import * as queryString from '@brandedholdings/query-string';
import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { partials as pageMeta } from '../data/page_meta.yml';
import { siteMeta } from '../data/site_meta.yml';
import PageWrapper from '../components/DefaultPageWrapper';
import {
    Flash,
    Link,
    SEO,
    TrustIcons
} from '@brandedholdings/react-components';

class Partials extends React.Component {
    constructor(props) {
        super(props);
        
        this.queryParams = queryString.parse(this.props.location.search);
    }

    getChildContext() {
        return { siteMeta };
    }

    getResumeUrl() {
        const pegasusBaseUrl = siteMeta.pegasusBaseUrl;
        const resumeUrlEncoded = this.queryParams.resume_link;
        let resumeUrl = '/secure/returning/all-info'; // default

        if (resumeUrlEncoded) {
            resumeUrl = pegasusBaseUrl + atob(resumeUrlEncoded).replace('/rest', ''); // remove /rest from resume link as pegasusBaseUrl already contains this
        }

        return resumeUrl;
    }

    getPartialContent() {
        const step = this.queryParams.resume_step;

        // return
        switch (step) {
            case 'phone-number':
                return (
                    <div>
                        <h3 className="center"><em>&ldquo;Am I going to be called on the phone?&rdquo;</em></h3>
                        <p>{ siteMeta.name } does not make phone calls regarding our many products and services. However, lending partners may choose to contact you by phone under some circumstances.</p>
                    </div>
                )

            case 'income-source':
                return (
                    <div>
                        <h3 className="center"><em>&ldquo;I have multiple sources of income. What do I do?&rdquo;</em></h3>
                        <p>Answer as best you can. Use whatever source of income makes up the largest part of your income.</p>
                    </div>
                )

            case 'residence':
                return (
                    <div>
                        <h3 className="center"><em>&ldquo;I don't want to give out my address.&rdquo;</em></h3>
                        <p>We understand &mdash; you're a private person. But if a lender chooses to work with you, we're sure you can understand why they'd need your address.</p>
                    </div>
                )

            case 'monthly-income':
                return (
                    <div>
                        <h3 className="center"><em>&ldquo;My income amount is complicated.&rdquo;</em></h3>
                        <p>"Income amount" is asking for your total income. It's common for people have multiple jobs or sources of income. Just add up everything you bring in a month and use that.</p>
                    </div>
                )

            case 'bank-account':
                return (
                    <div>
                        <h3 className="center"><em>&ldquo;Do I absolutely need a bank account?&rdquo;</em></h3>
                        <p>You do but almost any checking or savings account will work. This is the only way for lending partners to send or receive borrowed funds.</p>
                        <p>Don't have a bank account? Consider applying for a secured credit card. They're prepaid and can help establish a credit history and build your credit.</p>
                    </div>
                )

            case 'paydates':
                return (
                    <div>
                        <h3 className="center"><em>&ldquo;The dates I get paid can't be entered on the website?&rdquo;</em></h3>
                        <p>Sometimes you get paid on days that are a little different than most people and our system may tell you that it can't accept them. If that happens, select the date(s) closest to your pay dates as you can.</p>
                    </div>
                )

            case 'employment-info':
                return (
                    <div>
                        <h3 className="center"><em>&ldquo;My income is from benefits and I don't have any employer information. What do I do?&rdquo;</em></h3>
                        <p>If you were previously employed, use the most recent information you can find or remember.</p>
                        <p>If you receive benefits from a government agency, find their information online and use that.</p>
                    </div>
                )

            case 'personal-info':
                return (
                    <div>
                        <h3 className="center"><em>&ldquo;My income amount is complicated.&rdquo;</em></h3>
                        <p>"Income amount" is asking for your total income. It's common for people have multiple jobs or sources of income. Just add up everything you bring in a month and use that.</p>
                    </div>
                )

            case 'deposit-info':
                return (
                    
                    <div>
                        <h3 className="center"><em>&ldquo;I don't know my bank account information&rdquo;</em></h3>
                        <p>You can find your routing and account number several ways.</p>
                        <ol className="list">
                            <li>
                                <strong>It's printed on your paper checks</strong><br />
                                The <strong>routing number is always 9 digits long</strong> and located to the left of your checking account number.<br />
                                <img src="/assets/images/check-example.png" alt="where to find routing and account number" />
                            </li>
                            <li>
                                <strong>Log into your bank or credit union's website.</strong><br />
                                Most online banking systems will show you this information after logging in (although some of the numbers usually hidden for your privacy until you choose to see them)
                            </li>
                            <li>
                                <strong>Call your bank or credit union and ask them</strong><br />
                                Call your bank or credit union and ask them for your information.
                            </li>
                        </ol>
                    </div>                   
                )

            default:
                return (
                    <div>
                        <h3 className="center">
                            <em>&ldquo;Is my information safe?&rdquo;</em>
                            <br />
                            <strong>Yes.</strong>
                        </h3>
                        <p>It's important to always be careful with your personal and financial information. Only disclose it to established trusted services and only when necessary.</p>
                        <Flash level="alert">{ siteMeta.name } encrypts your data using 2048-bit SSL protection and transmits it securely when your information is submitted.</Flash>
                    </div> 
                )
        }
    }

    render() {
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);
        const resumeUrl = this.getResumeUrl();

        const pageImages = {};
        page.data.pageImages.edges.forEach(({ node }) => pageImages[path.basename(node.absolutePath).split('.')[0]] = node);

        return (
            <PageWrapper>
                <SEO post={ post } />
                <BackgroundImage
                    className="background__gatsby background background__graphic"
                    fluid={pageImages.hero.image.fluid}
                >
                    <div className="layout">
                        <div className="layout-content center">
                            <h1>
                                There you are! We noticed you disappeared.
                            </h1>
                            <br />
                            <Link to={ resumeUrl } className="button button--big button--success button--full button--shadow">Resume Session</Link>
                            <br /><br />
                            <TrustIcons icons={['mcafee','secured']} height={26} siteMeta={siteMeta} />
                        </div>
                    </div>
                </BackgroundImage>
                <div className="background background__white">
                    <div className="layout layout--narrow">
                        <h4 className="center">Weren't <em>quite sure</em> about something?<br /><strong>That's totally normal.</strong></h4>
                        <p>When we talk to our users to find out why they left the form at <Link to={ resumeUrl }>the same place you did</Link>, they usually tell us it was because they wanted to ask us one very common question:</p>
                        <hr />

                        { this.getPartialContent() }

                        <div className="center">
                            <hr />
                            <h4>
                                Feel better about the process now?<br />
                                <strong>You can pick up right where you left off:</strong>
                            </h4>
                            <br /><br />
                            <Link to={ resumeUrl } className="button button--big button--success button--full">Resume Session</Link>
                            <br /><br />
                            <TrustIcons icons={['mcafee','secured']} height={26} siteMeta={siteMeta} />
                        </div>
                        
                    </div>
                </div>
            </PageWrapper>
        );
    }
};

Partials.childContextTypes = {
    siteMeta: PropTypes.object
};

export default Partials;

export const query = graphql`
    {
        pageImages: allFile(filter: {absolutePath: {regex: "^static/assets/images/covers/"}}) {
            edges {
                node {
                    id
                    absolutePath
                    image: childImageSharp {
                        fluid(
                            quality: 90, 
                            maxWidth: 2000,
                            traceSVG: { background: "#0ba7e2", color: "#0ba7e2" }
                        ) {
                            # base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    }
`;